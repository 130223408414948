import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-ambassador',
    templateUrl: './ambassador.component.html',
    styleUrl: './ambassador.component.scss'
})
export class AmbassadorComponent implements OnInit {
    currentTab = 'onboarding';

    tabs = [{
        name: 'Onboarding',
        key: 'onboarding'
    }, {
        name: 'Liquidity',
        key: 'liquidity'
    }, {
        name: '$CRED',
        key: 'cred'
    }, {
        name: 'Members',
        key: 'members'
    }, {
        name: 'Leaderboard',
        key: 'leaderboard'
    }];

    constructor(
        private httpService: HttpService,
        private cfAlertService: CfAlertService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (!this.httpService.user.ambassador) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'You are not an ambassador'
                },
                status: 404
            }));

            this.router.navigate([ '/dashboard' ]);

            return;
        }

        this.currentTab = this.tabs[0].key;

        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.key === tab)) 
                    this.currentTab = tab;
            }
        });
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
